import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAJREI_mf6S5PzbiJwFfQLqpsQNHD6v5hs",
  authDomain: "shishupuram-81173.firebaseapp.com",
  projectId: "shishupuram-81173",
  storageBucket: "shishupuram-81173.appspot.com",
  messagingSenderId: "761053835701",
  appId: "1:761053835701:web:4ce7830366e8738da2e9fc"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenData = () => {
  return getToken(messaging, { vapidKey: 'BHg93SYwx96t0bvylrdhvaSpBnELVGAM57WRFzzvD6ty5KHGGXg2eXvRQBFcbuFDKX1fX_fnwc7rQacp4gfuhzk' }).then((currentToken) => {
    if (currentToken) {
      console.log('@@@ Firebase Registration TOKEN =====', currentToken);
      localStorage.setItem('fcmToken', currentToken.toString())
    } else {
      console.log('@@@ Firebase No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('@@@ Firebase Error: An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});