//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
//* Routes List start

const SplashscreenWeb = lazy(() =>
  import('../../blocks/splashscreen/src/Splashscreen.web')
);

const LoginPage = lazy(() =>
  import('../../blocks/mobile-account-login/src/LoginPage.web')
);

const MyJourney = lazy(() =>
  import('../../blocks/Settings5/src/MyJourney.web')
);

const MyDetails = lazy(() =>
  import('../../blocks/Settings5/src/MyDetails.web')
);

const SignupPage = lazy(() =>
  import('../../blocks/mobile-account-registration/src/SignupPage')
);

const HomeScreen = lazy(() =>
  import('../../blocks/dashboard/src/HomeScreen.web')
);

const Courses = lazy(() => import('../../blocks/dashboard/src/Courses.web'));

const MyProgress = lazy(() =>
  import('../../blocks/dashboard/src/MyProgress.web')
);

const UserProfile = lazy(() =>
  import('../../blocks/Settings5/src/UserProfile/UserProfile.web')
);

const MainSettings = lazy(() =>
  import('../../blocks/Settings5/src/MainSetting/MainSetting.Web')
);

const Coursevideos = lazy(() =>
  import('../../blocks/dashboard/src/Coursevideos.web')
);

const PaymentsDetails = lazy(() =>
  import('../../blocks/Payments/src/PaymentsDetails.web')
);
const PaymentsConfirmation = lazy(() =>
  import('../../blocks/Payments/src/PaymentsConfirmation.web')
);
const ProgressVideo = lazy(() =>
  import('../../blocks/dashboard/src/Progressvideo.web')
);
const Webinar = lazy(() =>
  import('../../blocks/Webinars/src/Webinar.web')
);
const Webinarbookslot = lazy(() =>
  import('../../blocks/Webinars/src/WebinarDetails.web')
);
const BlogPostManagement = lazy(() =>
  import('../../blocks/BlogPostsManagement/src/BlogPost/BlogPostManagement.web')
);
const BlogPostDetails = lazy(() =>
  import('../../blocks/BlogPostsManagement/src/BlogPost/BlogPostDetails.web')
);
const MainSetting = lazy(() =>
  import('../../blocks/Settings5/src/MainSetting/MainSetting.Web')
);
const AdminLayout = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminHome/AdminLayout.admin.web')
);
const AdminDashboard = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminHome/Dashboard.admin.web')
);
const AdminOrdersHistory = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminHome/OrdersHistory.admin.web')
);
const AdminSaleHistory = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminHome/SaleHistory.admin.web')
);
const AdminUserList = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminHome/UserList.admin.web')
);
const AdminUserListCourse = lazy(() =>
  import('../../blocks/AdminConsole3/src/AdminHome/UserListCourse.admin.web')
);
const AdminCourses = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/Courses.admin.web')
);
const AdminCreateCourse = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/CreateCourse.admin.web')
);
const AdminEditCourse = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/EditCourse.admin.web')
);
const AdminWebinar = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/Webinar.admin.web')
);
const AdminCreateWebinar = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/CreateWebinar.admin.web')
);
const AdminEditWebinar = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/EditWebinar.admin.web')
);
const AdminQuerylist = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/QuerryList.admin.web')
);
const AdminCustomNotification = lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/CustomNotification.admin.web')
);
const AdminNewNotification= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/NewNotification.admin.web')
);
const AdminBlog= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/Blog.admin.web')
);
const AdminCreateBlog= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/CreateBlog.admin.web')
);
const AdminEditBlog= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/EditBlog.admin.web')
);
const AdminSetting= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminSetting/AccountSetting.admin.web')
);
const AccountTermCondition= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminSetting/TermCondition.admin.web')
);
const AccountPrivacyPolicy= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminSetting/PrivacyPolicy.admin.web')
);
const AccountFAQ= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminSetting/Faq.admin.web')
);
const NotificationSetting= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminSetting/NotificationSetting.admin.web')
);
const AboutUs= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminSetting/AboutUs.admin.web')
);
const EditNotification= lazy(() =>
import('../../blocks/AdminConsole3/src/AdminHome/EditNotification.admin.web')
);


// /* Routes List End /

// /* Private Routes start /
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';

// /* Private Roues End /

function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <SplashscreenWeb {...props} />}
          />

          <Route
            path="/loginpage"
            exact
            render={props => <LoginPage {...props} />}
          />
          <Route
            path="/SignupPage"
            exact
            render={props => <SignupPage {...props} />}
          />

          <Route
            path="/myjourney"
            exact
            render={props => <MyJourney {...props} />}
          />

          <Route
            path="/mydetails"
            exact
            render={props => <MyDetails {...props} />}
          />

          <PrivateRoute
            path="/home"
            exact
            render={props => <HomeScreen {...props} />}
          />
          <PrivateRoute
            path="/courses"
            exact
            render={props => <Courses {...props} />}
          />
          <PrivateRoute
            path="/myProgress"
            exact
            render={props => <MyProgress {...props} />}
          />
          <PrivateRoute
            path="/userprofile"
            exact
            render={props => <UserProfile {...props} />}
          />
          <PrivateRoute
            path="/settings"
            exact
            render={props => <MainSettings {...props} />}
          />
          <PrivateRoute
            path="/courses/:courseID"
            exact
            render={props => <Coursevideos {...props} />}
          />
           <PrivateRoute
            path="/paymentsDetails"
            exact
            render={props => <PaymentsDetails {...props} />}
          />
           <PrivateRoute
            path="/paymentsConfirmation"
            exact
            render={props => <PaymentsConfirmation {...props} />}
          />
            <PrivateRoute
            path="/home/:courseID"
            exact
            render={props => <ProgressVideo {...props} />}
          />
            <Route
            path="/webinar"
            exact
            render={props => <Webinar {...props} />}
          />
            <Route
            path="/webinar/:webinarID"
            exact
            render={props => <Webinarbookslot {...props} />}
          />
            <Route
            path="/blogpost"
            exact
            render={props => <BlogPostManagement {...props} />}
          />
            <Route
            path="/blogpostdetails/:courseId"
            exact
            render={props => <BlogPostDetails {...props} />}
          />
            <Route
            path="/setting"
            exact
            render={props => <MainSetting {...props} />}
          />
           <Route path="/admin">
            <AdminLayout>
              <Route
                path="/admin/dashboard"
                exact
                render={props => <AdminDashboard {...props} />}
              />
              <Route
                path="/admin/ordershistory"
                exact
                render={props => <AdminOrdersHistory {...props} />}
              />
              <Route
                path="/admin/salehistory"
                exact
                render={props => <AdminSaleHistory {...props} />}
              />
              <Route
                path="/admin/userlist"
                exact
                render={props => <AdminUserList {...props} />}
              />
              <Route
                path="/admin/courses"
                exact
                render={props => <AdminCourses {...props} />}
              />
              <Route
                path="/admin/webinar"
                exact
                render={props => <AdminWebinar {...props} />}
              />
              <Route
                path="/admin/querrylist"
                exact
                render={props => <AdminQuerylist {...props} />}
              />
              <Route
                path="/admin/customnotification"
                exact
                render={props => <AdminCustomNotification {...props} />}
              />
              <Route
                path="/admin/blog"
                exact
                render={props => <AdminBlog {...props} />}
              />
            </AdminLayout>
          </Route>
          <Route
            path="/userlistcourse"
            exact
            render={props => <AdminUserListCourse {...props} />}
          />
          <Route
            path="/createcourse"
            exact
            render={props => <AdminCreateCourse {...props} />}
          />
          <Route
            path="/editcourse"
            exact
            render={props => <AdminEditCourse {...props} />}
          />
          <Route
            path="/createwebinar"
            exact
            render={props => <AdminCreateWebinar {...props} />}
          />
          <Route
            path="/editwebinar"
            exact
            render={props => <AdminEditWebinar {...props} />}
          />
          <Route
            path="/newnotification"
            exact
            render={props => <AdminNewNotification {...props} />}
          />
           <Route
               path="/editNotification"
               exact
               render={props => <EditNotification {...props} />}
            />
          <Route
            path="/createblog"
            exact
            render={props => <AdminCreateBlog {...props} />}
          />
          <Route
            path="/editblog"
            exact
            render={props => <AdminEditBlog {...props} />}
          />
          <Route path="/adminsetting">
          <AdminSetting>
              <Route
                path="/adminsetting/termcondition"
                exact
                render={props => <AccountTermCondition {...props} />}
              />
              <Route
                path="/adminsetting/privacypolicy"
                exact
                render={props => <AccountPrivacyPolicy {...props} />}
              />
              <Route
                path="/adminsetting/aboutus"
                exact
                render={props => <AboutUs {...props} />}
              />
              <Route
                path="/adminsetting/faq"
                exact
                render={props => <AccountFAQ {...props} />}
              />
              <Route
                path="/adminsetting/notificationsetting"
                exact
                render={props => <NotificationSetting {...props} />}
              />
            
          </AdminSetting>
          </Route>
        
      </Switch>
      </Suspense>
    </Router>
  );
}

//@ts-ignore
export default withRouter(WebRoutes);
