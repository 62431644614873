// App.js - WEB
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import './App.css';
import 'antd/dist/antd.css';
import "video.js/dist/video-js.css";
import "videojs-extra-buttons/dist/videojs-extra-buttons.css";
import "videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css";
import { notification } from 'antd'
// @ts-ignore
import { onMessageListener, getTokenData } from './firebaseInit.js';

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

class App extends Component {

  async componentDidMount() {
    Notification.requestPermission().then((permission) => {
      console.log('@@@ Notification Permission ======', permission);
      getTokenData();
      this.startMessageListener();
    });
  }

  startMessageListener = () => {
    onMessageListener().then((payload) => {
      console.log('@@@ Notification Payload =======', payload);
      notification.open({
        message: payload.notification.title,
        description:
          payload.notification.body,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }).catch((err) => console.log('sssss: ', err));
  }

  render() {
    return (
      <>
        <WebRoutes />
      </>
    );
  }
}

export default App;
